import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageHeader from "../components/page-header";
import ContactRows from "../components/contact-rows";

export const query = graphql`
  query ContactPageTemplateQuery($id: String!) {
    pageContact: sanityPageContact(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      description
      featuredImage {
        ...SanityImage
        alt
      }
      contactRows {
        _key
        title
        columns {
          _key
          _rawContent
        }
      }
    }
  }
`;

const ContactPageTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const pageContact = (data || {}).pageContact;

  return (
    <Layout>
      <Seo
        title={pageContact.title}
        description={pageContact.description}
        image={pageContact.featuredImage}
      />

      {pageContact.title && (<PageHeader title={pageContact.title} />)}
      {pageContact.contactRows && pageContact.contactRows.length > 0 && (<ContactRows items={pageContact.contactRows} />)}
    </Layout>
  );
};

export default ContactPageTemplate;

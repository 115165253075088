import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/contact-rows.module.css";

function ContactRows({ items }) {
  return (
    <div className={styles.root}>
      <Container>
        {items.map(({ _key, title, columns }) => {
          return (
            <div className={styles.row} key={_key}>
              {title && (<h2 className={styles.title}>{title}</h2>)}
              <div className="grid">
                {columns.map(({ _key, _rawContent }) => {
                  return (
                    <div className={cn("col-12 col-md-4", styles.col)} key={_key}>
                      {_rawContent && (<PortableText blocks={_rawContent} />)}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Container>
    </div>
  );
}

export default ContactRows;
